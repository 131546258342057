import { Stack } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const WorkStatementAvatarCellSkeleton = () => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Skeleton circular width={32} height={32} />
    <TextSkeleton width={120} variant="body2" />
  </Stack>
);
