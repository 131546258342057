import { useLayoutEffect, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { areDatesInTheSameDay, formatDate } from 'utils/dateUtils';
import { useBillingCycle } from 'hooks/useBillingCycle/useBillingCycle';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useReport } from 'hooks/useReport/useReport';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { ProjectTimesheetDataContext } from '../projectTimesheetDataContext/ProjectTimesheetDataContext';
import { projectsKeys } from 'utils/queryKeys';
import { ProjectTimesheetDataContextType } from '../projectTimesheetDataContext/ProjectTimesheetDataContext.types';
import { HourType } from '../../../api/types/HourType.enum';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { isStartOrEndDateAfterJanuary } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController.utils';

import { ProjectTimesheetDataContextControllerProps } from './ProjectTimesheetDataContextController.types';
import { getTimesheetMembers, mapTeamObjectToTeamsHoursArray } from './timesheetData.utils';

export const ProjectTimesheetDataContextController = ({ children }: ProjectTimesheetDataContextControllerProps) => {
  const {
    timesheetMonthStartDate,
    setTimesheetMonthStartDate,
    timesheetMonthDatesParsed: dates,
  } = useProjectTimesheet();
  const { projectDetails } = useProjectDetails();

  const enableBillingCycleFetch =
    ![ProjectStage.lead, ProjectStage.archived].includes(projectDetails.stage) &&
    isStartOrEndDateAfterJanuary(projectDetails.startDate, projectDetails.endDate);

  const {
    isLoading,
    isFetching: isFetchingTimesheetData,
    fetchStatus,
    error,
    data: billingCycleData,
  } = useBillingCycle(projectDetails.id, formatDate(timesheetMonthStartDate), enableBillingCycleFetch);
  const { isReport, reportDate } = useReport();
  // isLoadingAndEnabled from new RQ abstraction from latest starter
  const isLoadingTimesheetData = isLoading && fetchStatus !== 'idle';

  useLayoutEffect(() => {
    if (isReport && reportDate) {
      setTimesheetMonthStartDate(new Date(reportDate));
    }
  }, [isReport, reportDate]);

  const queryClient = useQueryClient();
  const invalidateBillingCycleCache = () =>
    queryClient.invalidateQueries(
      projectsKeys.projectBillingCycle(projectDetails.id, formatDate(timesheetMonthStartDate)),
    );

  const invoiceStatus = billingCycleData?.result?.currentData?.workStatements[0]?.invoiceStatus;
  const snapshotCreateDate = billingCycleData?.result?.snapshotData?.createdAt || '';
  const showTimesheetSnapshot = Boolean(
    billingCycleData?.result?.snapshotData?.workStatements.length &&
      invoiceStatus &&
      [InvoiceStatusEnum.sent, InvoiceStatusEnum.paid].includes(invoiceStatus),
  );
  const snapshotOrLiveData = showTimesheetSnapshot
    ? billingCycleData?.result?.snapshotData
    : billingCycleData?.result?.currentData;
  const showMismatchDataError = Boolean(snapshotOrLiveData?.isMismatch);

  const timesheetData = useMemo(
    () =>
      snapshotOrLiveData
        ? snapshotOrLiveData.workStatements.map((workStatement) => ({
            id: workStatement.id,
            statementNumber: workStatement.statementNumber,
            teams: mapTeamObjectToTeamsHoursArray(workStatement.teams),
          }))
        : [],
    [isLoading, billingCycleData],
  );

  const isTimesheetEditBlocked = Boolean(
    invoiceStatus &&
      [
        InvoiceStatusEnum.approved_by_pm,
        InvoiceStatusEnum.paid,
        InvoiceStatusEnum.sent,
        InvoiceStatusEnum.invoice_issued,
      ].includes(invoiceStatus),
  );

  const timesheetMembers = getTimesheetMembers(timesheetData);

  const timesheetMonthDatesParsed = dates.map((date) => ({
    ...date,
    isHoliday: timesheetMembers
      .flatMap(({ teamMemberHours }) =>
        teamMemberHours.filter((hour) => areDatesInTheSameDay(new Date(hour.date), date.value)),
      )
      .every((h) => h.hours.every((hour) => hour.type === HourType.holiday)),
  }));

  const value: ProjectTimesheetDataContextType = useMemo(
    () => ({
      isLoadingTimesheetData,
      isFetchingTimesheetData,
      timesheetData,
      billingCycleError: error,
      billingCycleId: billingCycleData?.result?.billingCycleId ?? '',
      projectId: projectDetails.id,
      invalidateBillingCycleCache,
      showTimesheetSnapshot,
      snapshotCreateDate,
      showMismatchDataError,
      isReport,
      timesheetMembers,
      timesheetMonthDatesParsed,
      isTimesheetEditBlocked,
    }),
    [
      isLoadingTimesheetData,
      isFetchingTimesheetData,
      timesheetData,
      billingCycleData?.result?.billingCycleId,
      projectDetails.id,
      error,
      invalidateBillingCycleCache,
      showTimesheetSnapshot,
      snapshotCreateDate,
      showMismatchDataError,
      isReport,
      timesheetMembers,
      timesheetMonthDatesParsed,
      isTimesheetEditBlocked,
    ],
  );

  return <ProjectTimesheetDataContext.Provider value={value}>{children}</ProjectTimesheetDataContext.Provider>;
};
