import { Member, Timesheet } from 'api/types/Timesheet.types';
import {
  TimesheetTeam,
  TimesheetTeamMember,
  TimesheetWorkStatement,
} from '../projectTimesheetDataContext/ProjectTimesheetDataContext.types';
import { HourType } from 'api/types/HourType.enum';

export const teamMemberHourRates = (teamMemberHours: TimesheetTeamMember['teamMemberHours']) => {
  const hourRates: Record<HourType, number[]> = {
    [HourType.regular]: [],
    [HourType.overhour]: [],
    [HourType.absence]: [],
    [HourType.holiday]: [],
    [HourType.total]: [],
  };
  return teamMemberHours.reduce(
    (rates, { hours }) => {
      hours.forEach((hour) => {
        if (rates[hour.type] && !rates[hour.type].includes(hour.ratePercentage)) {
          rates[hour.type].push(hour.ratePercentage);
        }
      });

      return rates;
    },
    { ...hourRates },
  );
};

export const hasMultipleHourEntries = (teamMember: Member) => {
  const uniqueHourRates = new Set();

  for (const billingDay of teamMember.billingDays) {
    if (billingDay.hours.length > 1) return true;

    const hour = billingDay.hours[0];

    if (hour?.ratePercentage) {
      uniqueHourRates.add(hour.ratePercentage);
    }
  }

  return uniqueHourRates.size > 1;
};

export const mapTeamObjectToTeamsHoursArray = (teams: Timesheet['workStatements'][number]['teams']): TimesheetTeam[] =>
  teams.map((team) => {
    const teamMembers = team.members.map((teamMember) => ({
      id: teamMember.id,
      role: teamMember.role,
      assignmentId: teamMember.assignmentId,
      name: `${teamMember.firstName} ${teamMember.lastName}`,
      hasMultipleHourEntries: hasMultipleHourEntries(teamMember),
      teamMemberHours: teamMember.billingDays,
      totalHours: teamMember.totalHours,
      rates: teamMemberHourRates(teamMember.billingDays),
    }));

    return {
      id: team.id,
      name: team.name,
      members: teamMembers,
      teamHoursSum: team.totalHours,
    };
  });

export const getTimesheetMembers = (timesheet: TimesheetWorkStatement[]) =>
  timesheet.flatMap(({ teams }) => teams.flatMap(({ members }) => members));
