import { useMutation } from '../useMutation/useMutation';
import { updateTeamName } from 'api/actions/updateTeamName/updateTeamName';
import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';

export const useUpdateTeamName = (projectId: string, workStatementId: string, teamId: string) => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['updateTeamName'], updateTeamName(workStatementId, teamId), {
    onSuccess: () => {
      invalidateBillingCycleCache();
    },
  });
};
