import { useState } from 'react';

import { Box, InputBase } from '@mui/material';
import { Button, Typography } from 'lux/components';
import { EditIcon, SaveIcon } from 'lux/icons';

import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';

import * as styles from './TeamName.styles';
import { TeamNameProps } from './TeamName.types';

export const TeamName = ({ teamName, isEditMode, setIsEditMode, onHandleSaveTeamName }: TeamNameProps) => {
  const [teamNameInTextField, setTeamNameInTextField] = useState<string>(teamName);
  const { showTimesheetSnapshot } = useProjectTimesheetData();
  const { isTimesheetEditBlocked } = useProjectTimesheetData();
  const { isReport } = useProjectTimesheetData();

  return isEditMode ? (
    <Box sx={styles.teamEdit}>
      <InputBase
        margin="none"
        fullWidth
        size="small"
        value={teamNameInTextField}
        sx={styles.teamNameTextField}
        onChange={(event) => setTeamNameInTextField(event.target.value)}
      />
      <Button
        iconOnly
        variant="text"
        size="small"
        color="primary"
        onClick={() => onHandleSaveTeamName(teamNameInTextField)}
      >
        <SaveIcon />
      </Button>
    </Box>
  ) : (
    <Box sx={styles.teamInfo}>
      <Typography variant="body2" sx={styles.teamName}>
        {teamName}
      </Typography>
      {!isTimesheetEditBlocked && (
        <Button
          sx={styles.editButton}
          iconOnly
          variant="text"
          size="small"
          onClick={() => setIsEditMode(true)}
          disabled={isReport || showTimesheetSnapshot}
        >
          <EditIcon />
        </Button>
      )}
    </Box>
  );
};
