import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddMembersToTeamParams } from './addMembersToTeam.types';

export const addMembersToTeam: (workStatementId: string, teamId: string) => MutationFn<AddMembersToTeamParams> =
  (workStatementId, teamId) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/teams/${teamId}/members`,
    method: 'PUT',
    params: body,
  });
