import { Styles } from 'lux/theme';
import { SxProps } from '@mui/material';

export const teamWrapper: SxProps = {
  borderTop: '1.5px solid',
  borderTopColor: 'border.default',
};

export const teamCell: SxProps = {
  backgroundColor: 'warning.light',
};

export const innerWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
};
