import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddTeamToWorkStatementParams, AddTeamToWorkStatementResponse } from './addTeamToWorkStatement.types';

export const addTeamToWorkStatement: (
  workStatementId: string,
) => MutationFn<AddTeamToWorkStatementParams, AddTeamToWorkStatementResponse> = (workStatementId) => (body) => ({
  endpoint: `/work-statements/${workStatementId}/teams`,
  method: 'POST',
  params: body,
});
