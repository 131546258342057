import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';
import { useMutation } from '../useMutation/useMutation';
import { removeMembersFromTeam } from 'api/actions/removeMembersFromTeam/removeMembersFromTeam';

export const useRemoveMemberFromTeam = (workStatementId: string, teamId: string) => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['addMembersToTeam'], removeMembersFromTeam(workStatementId, teamId), {
    onSuccess: () => {
      invalidateBillingCycleCache();
    },
  });
};
