import { Styles } from 'lux/theme';
import { SxProps } from '@mui/material';

import { CELL_HEIGHT, DEFAULT_BORDER_PROPERTIES } from '../../../tableComponents/tableComponents';

export const cell: SxProps = {
  '&&': {
    paddingLeft: 0,
  },
  borderRight: DEFAULT_BORDER_PROPERTIES,
};

export const innerWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
};

export const paper: Styles = {
  fontSize: 14,
};

export const addMemberButton: Styles = {
  flex: 1,
  height: CELL_HEIGHT,
  '&&': {
    justifyContent: 'flex-start',
    paddingLeft: 2.5,
    fontSize: 12,
    borderRadius: 0,
  },
  '&&:hover': {
    backgroundColor: 'inherit',
  },
};
