import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';
import { useMutation } from '../useMutation/useMutation';
import { closeTeam } from '../../api/actions/closeTeam/closeTeam';

export const useCloseTeam = (workStatementId: string, teamId: string) => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['closeTeam'], closeTeam(workStatementId, teamId), {
    onSuccess: () => {
      invalidateBillingCycleCache();
    },
  });
};
