import { Box } from '@mui/material';
import { Button } from 'lux/components';
import { AddUserIcon } from 'lux/icons';
import { useSnackbar } from 'notistack';

import { AssignWithoutNeedHandler } from 'shared/assignWithoutNeedModal/AssignWithoutNeedModal.types';
import { getApiError } from 'api/utils/getApiError';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useAssignPerson } from 'hooks/useAssignPerson/useAssignPerson';
import { Translation } from 'ui/translation/Translation';
import { AssignWithoutNeedModal } from 'shared/assignWithoutNeedModal/AssignWithoutNeedModal';
import { TableCell, TableRow } from 'app/project/projectTimesheet/timesheetTable/tableComponents/tableComponents';
import { HoursSum } from 'app/project/projectTimesheet/timesheetTable/timesheetTableBody/hoursSum/HoursSum';
import { EmptyHourDayCells } from 'app/project/projectTimesheet/timesheetTable/timesheetTableBody/emptyHourDayCells/EmptyHourDayCells';
import { useProjectTimesheetData } from '../../../../../../../hooks/useProjectTimesheetData/useProjectTimesheetData';

import * as styles from './AssignMemberWithoutNeed.styles';

export const AssignMemberWithoutNeed = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();
  const {
    projectDetails: { id: projectId },
  } = useProjectDetails();
  const {
    isOpen: isAssignWithoutNeedModalOpen,
    setOpen: setAssignWithoutNeedModalOpen,
    setClose: setAssignWithoutNeedModalClose,
  } = useDialog();
  const { mutate: assignPersonToProject, isLoading } = useAssignPerson(projectId);
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  const handleAssignWithoutNeed: AssignWithoutNeedHandler = (payload, { onSuccess, onError }, assignedEmployee) => {
    assignPersonToProject(payload, {
      onSuccess: () => {
        onSuccess();
        invalidateBillingCycleCache();
        enqueueSnackbar(t('assignWithoutNeed.request.success', assignedEmployee));
      },
      onError: (err) => {
        onError(err);

        if (err.response?.status !== 409) {
          enqueueSnackbar(getApiError(err));
        }
      },
    });
  };

  return (
    <TableRow>
      <TableCell sx={styles.cell}>
        <Box sx={styles.innerWrapper}>
          <Button
            onClick={setAssignWithoutNeedModalOpen}
            size="small"
            variant="text"
            fullWidth
            startIcon={<AddUserIcon />}
            sx={styles.addMemberButton}
            disabled={isAssignWithoutNeedModalOpen}
          >
            <Translation id="projectDetails.timesheet.assignWithoutNeed" />
          </Button>
          <HoursSum />
        </Box>
        <AssignWithoutNeedModal
          projectId={projectId}
          isOpen={isAssignWithoutNeedModalOpen}
          onClose={setAssignWithoutNeedModalClose}
          isAssignmentInProgress={isLoading}
          onAssign={handleAssignWithoutNeed}
        />
      </TableCell>
      <EmptyHourDayCells />
    </TableRow>
  );
};
