import { useMemo, useState } from 'react';

import { ProjectBillingSummaryContext } from '../projectBillingSummaryContext/ProjectBillingSummaryContext';
import { BillingSummaryGroupBy } from 'api/types/BillingSummary.types';
import { ProjectBillingSummaryContextType } from '../projectBillingSummaryContext/ProjectBillingSummaryContext.types';

import { ProjectBillingSummaryContextControllerProps } from './ProjectBillingSummaryContextController.types';

export const ProjectBillingSummaryContextController = ({ children }: ProjectBillingSummaryContextControllerProps) => {
  const [groupBy, setGroupBy] = useState(
    process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' ? BillingSummaryGroupBy.TEAMS : BillingSummaryGroupBy.MEMBERS,
  );

  const value: ProjectBillingSummaryContextType = useMemo(() => ({ groupBy, setGroupBy }), [groupBy, setGroupBy]);

  return <ProjectBillingSummaryContext.Provider value={value}>{children}</ProjectBillingSummaryContext.Provider>;
};
