import { SxProps } from '@mui/system';

import { Styles } from 'styles/theme';

export const teamRow: Styles = {
  '&& .MuiTableCell-root': {
    backgroundColor: 'accent.orange.pastel',
    height: 32,
    border: 0,
  },
};

export const collapseButton: SxProps = {
  '&.icon-only.MuiButton-sizeSmall': {
    padding: 0.5,
    color: 'text.secondary',
  },
};
