import { TableCell } from '../../tableComponents/tableComponents';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { useProjectTimesheetData } from '../../../../../../hooks/useProjectTimesheetData/useProjectTimesheetData';

import * as styles from './EmptyHourDayCells.styles';

export const EmptyHourDayCells = () => {
  const { timesheetMonthDatesParsed: days } = useProjectTimesheetData();
  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();

  return (
    <>
      {days.map((day, idx) => {
        const { isProjectStartDayMarker, isAfterProjectEnd, isBeforeProjectStart } = getIsFirstDayOfProject(
          Number(day.number),
        );

        return (
          <TableCell
            key={idx}
            isWeekend={day.isWeekend}
            isAbsent={day.isHoliday}
            isOutsideProjectDates={isAfterProjectEnd || isBeforeProjectStart}
            isEmpty
            sx={styles.cell(isProjectStartDayMarker)}
          />
        );
      })}
    </>
  );
};
