import { useState } from 'react';

import { Table } from 'lux/components';
import { Stack } from '@mui/material';

import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { CollapseRow } from '../../collapseRow/CollapseRow';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';

import * as styles from './WorkStatementTeam.styles';
import { WorkStatementTeamProps } from './WorkStatementTeam.types';
import { WorkStatementTeamMember } from './workStatementTeamMember/WorkStatementTeamMember';

export const WorkStatementTeam = ({ team: { name, members, total }, firstColumnSize }: WorkStatementTeamProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { formatPrice } = useBillingSummaryPrice();
  const { isReport } = useProjectBillingSummaryData();

  const teamHours = members.reduce((acc, { hours }) => acc + hours.reduce((acc, { amount }) => acc + amount, 0), 0);

  const displayedTeamHours = Number.isInteger(teamHours) ? teamHours : teamHours.toFixed(2).replace(/\.?0+$/, '');

  return (
    <>
      <Table.Row sx={styles.teamRow}>
        <Table.Cell>
          <Stack direction="row" alignItems="center" gap={1}>
            {name}
            {!isReport && (
              <CollapseButton
                sx={styles.collapseButton}
                isExpanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            )}
          </Stack>
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{displayedTeamHours}</Table.Cell>
        <Table.Cell>-</Table.Cell>
        <Table.Cell>{formatPrice(total)}</Table.Cell>
      </Table.Row>
      <CollapseRow isExpanded={isExpanded} colSpan={5}>
        {members.map((member) => (
          <WorkStatementTeamMember member={member} key={member.id} firstColumnSize={firstColumnSize} />
        ))}
      </CollapseRow>
    </>
  );
};
