import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../workStatementTableCell/WorkStatementTableCell';
import { Translation } from 'ui/translation/Translation';

import * as styles from './WorkStatementByTeam.styles';
import { WorkStatementTeamSkeleton } from './workStatementTeam/WorkStatementTeam.skeleton';

const FIRST_COLUMN_SIZE_IN_CHARS = 24;

export const WorkStatementByTeamSkeleton = () => (
  <Table.Table sx={styles.table}>
    <Table.Head>
      <Table.Row>
        <TableCell isLabel autoWidth sx={styles.firstColumn(FIRST_COLUMN_SIZE_IN_CHARS)}>
          <Translation id="projectDetails.billingSummary.team" />
        </TableCell>
        <TableCell isLabel autoWidth>
          <Translation id="projectDetails.billingSummary.role" />
        </TableCell>
        <TableCell isLabel autoWidth sx={styles.hoursCell}>
          <Translation id="projectDetails.billingSummary.hours" />
        </TableCell>
        <TableCell isLabel>
          <Translation id="projectDetails.billingSummary.rate" />
        </TableCell>
        <TableCell isLabel>
          <Translation id="projectDetails.billingSummary.total" />
        </TableCell>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      <WorkStatementTeamSkeleton firstColumnSize={FIRST_COLUMN_SIZE_IN_CHARS} />
    </Table.Body>
  </Table.Table>
);
