import { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { AddIcon, DownloadIcon } from 'lux/icons';
import { Button, Typography, Alert, Emoji } from 'lux/components';
import { enqueueSnackbar } from 'notistack';

import { AppMessages } from 'i18n/messages';
import { parseDate } from 'utils/dateUtils';
import { ProjectNavigationBar } from 'ui/projectNavigationBar/ProjectNavigationBar';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectBillingSummary } from 'hooks/useProjectBillingSummary/useProjectBillingSummary';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { Translation } from 'ui/translation/Translation';
import { WorkStatement } from 'api/types/BillingSummary.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { ProjectCardWrapper } from 'app/project/projectCardWrapper/ProjectCardWrapper';
import { ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { LastUpdate } from '../lastUpdate/LastUpdate';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';

import * as styles from './ProjectBillingSummary.styles';
import { BillingSummaryFilters } from './billingSummaryFilters/BillingSummaryFilters';
import { BillingSummaryWorkStatement } from './billingSummaryWorkStatement/BillingSummaryWorkStatement';
import { WorkStatementDetailsSideCard } from './workStatementDetailsSideCard/WorkStatementDetailsSideCard';
import { AddWorkStatementModal } from './workStatementModals/addWorkStatementModal/AddWorkStatementModal';
import { EditWorkStatementModal } from './workStatementModals/editWorkStatementModal/EditWorkStatementModal';
import { ProjectInvoices } from './projectInvoices/ProjectInvoices';
import { BillingSummaryEmptyState } from './billingSummaryEmptyState/BillingSummaryEmptyState';
import { DiscrepancyHoursWarning } from './discrepancyHoursWarning/DiscrepancyHoursWarning';
import { InvoiceErrorBanner } from './invoiceErrorBanner/InvoiceErrorBanner';
import { WarningMessage, getPeople } from './discrepancyHoursWarning/warningMessage/WarningMessage';
import { BillingSummaryWorkStatementSkeleton } from './billingSummaryWorkStatement/BillingSummaryWorkStatement.skeleton';
import { ProjectBillingSummaryWrapperStyles } from './ProjectBillingSummary.styles';

export const ProjectBillingSummary = () => {
  const [selectedWorkStatement, setSelectedWorkStatement] = useState<WorkStatement | null>(null);
  const [isWorkStatementDetailsSideCardOpen, setIsWorkStatementDetailsSideCardOpen] = useState(false);

  const { startDate: projectStartDate, projectDetails } = useProjectDetails();
  const { hasBillingCycleDiscrepancy } = useProjectDiscrepancy();
  const { groupBy, setGroupBy } = useProjectBillingSummary();
  const {
    isLoadingBillingSummaryData,
    workStatements,
    showWorkStatementSnapshot,
    dataMismatch,
    billingCycle,
    selectedDate,
    lastManualUpdate,
    goToPreviousMonth,
    goToNextMonth,
    goToCurrentMonth,
    billingSummaryPeople,
    billingSummaryError,
    confirmInvoiceError,
    setConfirmInvoiceError,
  } = useProjectBillingSummaryData();
  const { id, endDate, startDate, stage } = projectDetails;
  const { formatMessage, t } = useLocale();

  const invoiceErrorTitle = `${t('projectDetails.invoice.action.error.title.first.part')} ${t(
    'projectDetails.invoice.action.error.title.second.part',
  )}`;

  const isBillingCycleNotGenerated =
    [ProjectStage.lead, ProjectStage.archived].includes(stage) || isLoadingBillingSummaryData;
  const displayLastUpdateCondition = lastManualUpdate?.updatedAt && lastManualUpdate?.username;
  const isMissingWorkStatements = workStatements.length === 0;
  const showDiscrepancyWarning = hasBillingCycleDiscrepancy(
    projectDetails.id,
    parseDate(billingCycle?.startDate || ''),
  );
  const showMismatchDataError = dataMismatch?.subTotal || dataMismatch?.total;
  const reportRoute = `../${ProjectDetailsRoute.report}?date=${selectedDate.toISOString()}`;

  const handleSetSelectedWorkStatement = (workStatement: WorkStatement) => {
    setSelectedWorkStatement(workStatement);
    setIsWorkStatementDetailsSideCardOpen(true);
  };

  const handleSetWorkStatementFromInvoice = (workStatementNumber: string) => {
    const orderFromInvoice = workStatements.find(
      (workStatement) => workStatement.statementNumber === workStatementNumber,
    );

    if (orderFromInvoice) {
      handleSetSelectedWorkStatement(orderFromInvoice);
    } else {
      enqueueSnackbar(
        formatMessage(
          { id: AppMessages['projectDetails.invoices.notFound'] },
          { statementNumber: `${workStatementNumber}` },
        ),
      );
    }
  };

  const {
    isOpen: isAddWorkStatementModalOpen,
    setOpen: setAddWorkStatementModalOpen,
    setClose: setAddWorkStatementModalClose,
  } = useDialog();
  const {
    isOpen: isEditWorkStatementModalOpen,
    setOpen: setEditWorkStatementModalOpen,
    setClose: setEditWorkStatementModalClose,
  } = useDialog();

  useEffect(() => {
    if (setSelectedWorkStatement) {
      setSelectedWorkStatement(
        workStatements.find((workStatement) => workStatement.id === selectedWorkStatement?.id) ||
          workStatements?.[0] ||
          null,
      );
    }
  }, [workStatements]);

  useEffect(() => {
    if (confirmInvoiceError.message) {
      window.scrollTo(0, 0);
    }
  }, [confirmInvoiceError]);

  useEffect(() => {
    const confirmInvoiceSessionStorageData = sessionStorage.getItem('confirmInvoiceError');

    if (confirmInvoiceSessionStorageData) {
      const parsedConfirmInvoiceSessionStorageData = JSON.parse(confirmInvoiceSessionStorageData);

      parsedConfirmInvoiceSessionStorageData.invoiceId === selectedWorkStatement?.invoice?.id &&
        setConfirmInvoiceError(parsedConfirmInvoiceSessionStorageData);
    }
  }, [selectedWorkStatement]);

  const enableAddNewOrder = [ProjectStage.lead, ProjectStage.ongoing, ProjectStage.closing].includes(stage);

  const handleClose = () => setIsWorkStatementDetailsSideCardOpen(false);

  return (
    <Stack sx={styles.container} direction="row" spacing={3}>
      <Box sx={styles.wrapper(isWorkStatementDetailsSideCardOpen)}>
        <Stack spacing={4} width="100%" flex={1} position="relative">
          <ProjectCardWrapper
            sx={{
              wrapper: ProjectBillingSummaryWrapperStyles,
            }}
          >
            {showDiscrepancyWarning && (
              <DiscrepancyHoursWarning
                message={<WarningMessage people={getPeople(billingSummaryPeople)} />}
                to={ProjectDetailsRoute.timesheet}
                modifiedText
              />
            )}
            {confirmInvoiceError.message && (
              <InvoiceErrorBanner title={invoiceErrorTitle} message={confirmInvoiceError.message} />
            )}
            {showMismatchDataError && (
              <Alert
                severity="error"
                icon={<Emoji type="money" />}
                title={t('projectDetails.billingSummary.mismatchError.title')}
                description={t('projectDetails.billingSummary.mismatchError.description')}
                sx={styles.moneyEmoji}
              />
            )}

            <Stack width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                component={NavLink}
                to={reportRoute}
                startIcon={<DownloadIcon />}
                end
              >
                <Translation id="projectDetails.billingSummary.report" />
              </Button>
              {displayLastUpdateCondition && (
                <LastUpdate updatedAt={lastManualUpdate?.updatedAt} username={lastManualUpdate?.username} />
              )}
            </Stack>
            <Stack direction="row" mt={3} flex={1} justifyContent={'space-between'}>
              <Typography sx={styles.title}>
                <Translation id="projectDetails.billingSummary" />
              </Typography>
              {process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && enableAddNewOrder && (
                <Button
                  onClick={setAddWorkStatementModalOpen}
                  size="medium"
                  variant="outlined"
                  color="primary"
                  disabled={showWorkStatementSnapshot}
                  startIcon={<AddIcon />}
                >
                  <Translation id="projectDetails.billingSummary.addNewOrder" />
                </Button>
              )}
            </Stack>
            <ProjectNavigationBar
              selectedMonthStartDate={selectedDate}
              selectedMonthMinDate={projectStartDate}
              onPreviousMonthClick={goToPreviousMonth}
              onNextMonthClick={goToNextMonth}
              onCurrentMonthClick={goToCurrentMonth}
              isNavNextArrowDisabled={isBillingCycleNotGenerated}
              isNavPrevArrowDisabled={isBillingCycleNotGenerated}
            />
            {!isBillingCycleNotGenerated && !billingSummaryError && (
              <BillingSummaryFilters groupBy={groupBy} onGroupByChange={setGroupBy} />
            )}
            {isLoadingBillingSummaryData && <BillingSummaryWorkStatementSkeleton />}
            {!isLoadingBillingSummaryData && isMissingWorkStatements && <BillingSummaryEmptyState />}
            {workStatements.map((workStatement) => (
              <BillingSummaryWorkStatement
                key={workStatement.id}
                workStatement={workStatement}
                billingCycle={billingCycle}
                onClick={handleSetSelectedWorkStatement}
              />
            ))}
          </ProjectCardWrapper>
          <ProjectCardWrapper>
            <Typography sx={styles.title}>
              <Translation id="projectDetails.invoices.title" />
            </Typography>
            <ProjectInvoices openWorkStatement={handleSetWorkStatementFromInvoice} />
          </ProjectCardWrapper>
        </Stack>
      </Box>

      <WorkStatementDetailsSideCard
        workStatement={selectedWorkStatement}
        billingCycle={billingCycle}
        handleClose={handleClose}
        isOpen={isWorkStatementDetailsSideCardOpen}
        openEditOrder={setEditWorkStatementModalOpen}
        showWorkStatementSnapshot={showWorkStatementSnapshot}
      />
      <AddWorkStatementModal
        projectId={id}
        open={isAddWorkStatementModalOpen}
        onClose={setAddWorkStatementModalClose}
        projectStartDate={startDate}
        projectEndDate={endDate}
        projectCurrency={projectDetails.currency}
        useProjectStartDate={isMissingWorkStatements}
      />
      {selectedWorkStatement && (
        <EditWorkStatementModal
          open={isEditWorkStatementModalOpen}
          onClose={setEditWorkStatementModalClose}
          projectStartDate={startDate}
          projectEndDate={endDate}
          projectCurrency={projectDetails.currency}
          workStatement={selectedWorkStatement}
          useProjectStartDate={workStatements.length === 1}
        />
      )}
    </Stack>
  );
};
