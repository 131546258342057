import { useMemo } from 'react';

import { Avatar } from 'lux/components';

import { WorkStatementTeamMember } from 'api/types/BillingSummary.types';

import { PickRandomPictures, RandomAvatarsProps } from './RandomAvatars.types';
import * as styles from './RandomAvatars.styles';

const MAX_AMOUNT_OF_AVATARS = 4;

const getRandomMembers: PickRandomPictures = (initMembers) => {
  let members: WorkStatementTeamMember[] = [...initMembers];
  const randomMembers: WorkStatementTeamMember[] = [];
  const length = initMembers.length > MAX_AMOUNT_OF_AVATARS ? MAX_AMOUNT_OF_AVATARS : initMembers.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * members.length);
    const randomMember = members[randomIndex];
    randomMembers.push(randomMember);
    members = members.filter((member) => member.id !== randomMember.id);
  }

  return randomMembers.map((member) => ({
    id: `avatar-${member.id}`,
    fullName: `${member.firstName} ${member.lastName}`,
    picture: member.picture,
  }));
};

export const RandomAvatars = ({ members }: RandomAvatarsProps) => {
  const randomMembers = useMemo(() => getRandomMembers(members), [members]);

  return (
    <>
      {randomMembers.map(({ picture, id, fullName }, idx) => (
        <Avatar size="sm" key={id} image={picture} alt={fullName} sx={styles.avatar(idx)} />
      ))}
    </>
  );
};
