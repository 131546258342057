import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../../../workStatementTableCell/WorkStatementTableCell';
import { useBillingSummaryMemberHours } from 'hooks/useBillingSummaryMemberHours/useBillingSummaryMemberHours';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { HourDetailsRow } from '../../../hourDetailsRow/HourDetailsRow';
import { WorkStatementAvatarCell } from '../../../workStatementAvatarCell/WorkStatementAvatarCell';

import { WorkStatementTeamMemberProps } from './WorkStatementTeamMember.types';
import * as styles from './WorkStatementTeamMember.styles';

export const WorkStatementTeamMember = ({
  member: { firstName, lastName, picture, hours, total, role },
  firstColumnSize,
}: WorkStatementTeamMemberProps) => {
  const { formatPrice } = useBillingSummaryPrice();
  const { totalHours, rateText } = useBillingSummaryMemberHours(hours, false);

  return (
    <>
      <Table.Row>
        <Table.Cell sx={styles.firstColumn(firstColumnSize)}>
          <WorkStatementAvatarCell fullName={`${firstName} ${lastName}`} picture={picture} />
        </Table.Cell>
        <TableCell autoWidth>{role}</TableCell>
        <TableCell autoWidth sx={styles.hoursCell}>
          {totalHours}
        </TableCell>
        <TableCell>{rateText}</TableCell>
        <TableCell>{formatPrice(total)}</TableCell>
      </Table.Row>
      {hours.length > 1 &&
        hours.map((hour, idx) => (
          <HourDetailsRow key={idx} hour={hour} isLastRow={idx === hours.length - 1} role={' '} />
        ))}
    </>
  );
};
