import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { RemoveMembersFromTeamParams } from './removeMembersFromTeam.types';

export const removeMembersFromTeam: (
  workStatementId: string,
  teamId: string,
) => MutationFn<RemoveMembersFromTeamParams> = (workStatementId, teamId) => (body) => ({
  endpoint: `/work-statements/${workStatementId}/teams/${teamId}/remove-members`,
  method: 'PATCH',
  params: body,
});
