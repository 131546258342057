import { useMutation } from '../useMutation/useMutation';
import { addTeamToWorkStatement } from '../../api/actions/addTeamToWorkStatement/addTeamToWorkStatement';
import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';

export const useAddTeamToWorkStatement = (workStatementId: string) => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['addTeamToWorkStatement'], addTeamToWorkStatement(workStatementId), {
    onSuccess: () => {
      invalidateBillingCycleCache();
    },
  });
};
