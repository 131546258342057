import { Box } from '@mui/material';
import { Button } from 'lux/components';
import { DeleteIcon } from 'lux/icons';

import { EmptyHourDayCells } from '../../emptyHourDayCells/EmptyHourDayCells';
import { TableCell, TableRow } from '../../../tableComponents/tableComponents';
import { HoursSum } from '../../hoursSum/HoursSum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useCloseTeam } from 'hooks/useCloseTeam/useCloseTeam';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';

import * as styles from './DeleteMemberRow.styles';
import { DeleteTeamRowProps } from './DeleteTeamRow.types';

export const DeleteTeamRow = ({ workStatementId, teamId }: DeleteTeamRowProps) => {
  const { formatMessage } = useLocale();
  const { mutate: closeTeam } = useCloseTeam(workStatementId, teamId);
  const { billingCycleId } = useProjectTimesheetData();

  const handleClick = () => closeTeam({ billingCycleId });

  return (
    <TableRow>
      <TableCell sx={styles.cell}>
        <Box sx={styles.innerWrapper}>
          <Button
            onClick={handleClick}
            size="small"
            variant="text"
            fullWidth
            startIcon={<DeleteIcon />}
            sx={styles.deleteMemberButton}
          >
            {formatMessage({ id: AppMessages['projectDetails.timesheet.deleteTeam'] })}
          </Button>
          <HoursSum />
        </Box>
      </TableCell>
      <EmptyHourDayCells />
    </TableRow>
  );
};
