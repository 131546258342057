import { Styles } from 'lux/theme';

export const innerWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
};

export const teamMemberInfo: Styles = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: 1,
};

export const avatar: Styles = {
  marginRight: 1,
};

export const memberTitle: Styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const memberRole: Styles = {
  ml: 1,
  fontSize: 14,
  color: 'text.secondary',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const userInfoWrapper: Styles = {
  display: 'grid',
  gridTemplateColumns: '1fr auto auto',
  alignItems: 'center',
};
