import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Avatar, Button, Tooltip, Typography } from 'lux/components';
import { DeleteIcon } from 'lux/icons';

import { areDatesEqual, getStartOfMonthDate, parseDate } from 'utils/dateUtils';
import { TableCell, TableRowCollapsed, TableRow } from '../../tableComponents/tableComponents';
import { HoursPerDayCell } from '../hoursPerDayCell/HoursPerDayCell';
import { HoursSum } from '../hoursSum/HoursSum';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { AppMessages } from 'i18n/messages';
import { HourType } from 'api/types/HourType.enum';
import { roundNumber } from 'utils/roundNumber';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { WarningTooltip } from '../warningTooltip/WarningTooltip';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';

import * as styles from './TeamMemberRow.styles';
import { TeamMemberRowProps } from './TeamMemberRow.types';
import { TeamMemberHoursDetailsRow } from './teamMemberHoursDetailsRow/TeamMemberHoursDetailsRow';

export const TeamMemberRow = ({ member, isEditMode, onMemberRemove, isParentExpanded }: TeamMemberRowProps) => {
  const [isMemberRowExpanded, setIsMemberRowExpanded] = useState(false);
  const [isRootSelected, setIsRootSelected] = useState(false);
  const [memberHoursData, setMemberHoursData] = useState({
    fteHoursThisMonth: 0,
    memberHoursSum: 0,
    isConflict: false,
  });

  const { formatMessage } = useLocale();
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const { setDiscrepancyData, discrepancyData } = useProjectDiscrepancy();
  const { isReport } = useProjectTimesheetData();

  const memberHoursSum = roundNumber(member.totalHours, 2);

  useEffect(() => {
    if (!isParentExpanded) {
      setIsMemberRowExpanded(false);
    }
  }, [isParentExpanded]);

  useEffect(() => {
    setMemberHoursData((prevValue) => ({ ...prevValue, isConflict: false }));

    if (!discrepancyData) {
      return;
    }

    const { startDate, discrepancyIdsWithHours } = discrepancyData;

    const currentUserDiscrepancyData = discrepancyIdsWithHours.find(({ teamMemberId }) => teamMemberId === member.id);

    if (currentUserDiscrepancyData?.scheduledHours === memberHoursSum) {
      const updatedDiscrepancyPeopleIds = discrepancyData.discrepancyPeopleIds.filter((id) => id !== member.id);
      const updatedDiscrepancyIdsWithHours = discrepancyIdsWithHours.filter(
        ({ teamMemberId }) => teamMemberId !== member.id,
      );
      const updatedDiscrepancyData = {
        ...discrepancyData,
        discrepancyIdsWithHours: updatedDiscrepancyIdsWithHours,
        discrepancyPeopleIds: updatedDiscrepancyPeopleIds,
      };

      setDiscrepancyData(updatedDiscrepancyPeopleIds.length === 0 ? null : updatedDiscrepancyData);
    }

    if (
      !currentUserDiscrepancyData ||
      !areDatesEqual(parseDate(startDate), getStartOfMonthDate(timesheetMonthStartDate))
    ) {
      return;
    }

    const { scheduledHours } = currentUserDiscrepancyData;

    const areValuesEquals = memberHoursSum === scheduledHours;

    !areValuesEquals && setMemberHoursData({ fteHoursThisMonth: scheduledHours, memberHoursSum, isConflict: true });
  }, [timesheetMonthStartDate, member]);

  const isPersonExpanded = () => {
    if (isEditMode) {
      return false;
    }

    if (isReport) {
      return true;
    }

    return isMemberRowExpanded;
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={styles.innerWrapper}>
            <Box sx={styles.teamMemberInfo}>
              <Box sx={styles.userInfoWrapper}>
                <Avatar size="xs" name={member.name} alt={member.name} sx={styles.avatar} />
                <Typography sx={styles.memberTitle}>{member.name}</Typography>
                <Tooltip title={<Typography>{member.role}</Typography>} arrow>
                  <Typography component="span" sx={styles.memberRole}>
                    {member.role}
                  </Typography>
                </Tooltip>
              </Box>
              {memberHoursData.isConflict && <WarningTooltip memberHoursData={memberHoursData} />}
            </Box>
            {process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && isEditMode && (
              <Button iconOnly variant="text" size="small" color="error" onClick={() => onMemberRemove(member.id)}>
                <DeleteIcon />
              </Button>
            )}
            {!isEditMode && member.hasMultipleHourEntries && !isReport && (
              <CollapseButton
                isExpanded={isMemberRowExpanded}
                onClick={() => setIsMemberRowExpanded(!isMemberRowExpanded)}
              />
            )}
            <HoursSum>{memberHoursSum}</HoursSum>
          </Box>
        </TableCell>
        {member.teamMemberHours.map((teamMemberHour, idx) => (
          <HoursPerDayCell
            dayNumber={idx + 1}
            key={idx}
            teamMemberHour={teamMemberHour}
            teamMemberId={member.id}
            setIsRootSelected={setIsRootSelected}
          />
        ))}
      </TableRow>

      {member.hasMultipleHourEntries && (
        <TableRowCollapsed isExpanded={isPersonExpanded()}>
          <>
            {member.rates.REGULAR.map((rate) => (
              <TeamMemberHoursDetailsRow
                key={rate}
                title={formatMessage({ id: AppMessages['projectDetails.timesheet.regularWorks.rate'] }, { rate })}
                hourType={HourType.regular}
                teamMemberHours={member.teamMemberHours}
                teamMemberId={member.id}
                rateToDisplay={rate}
                isRootSelected={isRootSelected}
              />
            ))}
            {member.rates.OVERHOUR.map((rate) => (
              <TeamMemberHoursDetailsRow
                key={rate}
                title={formatMessage({ id: AppMessages['projectDetails.timesheet.overtime.rate'] }, { rate })}
                hourType={HourType.overhour}
                teamMemberHours={member.teamMemberHours}
                teamMemberId={member.id}
                rateToDisplay={rate}
                isRootSelected={isRootSelected}
              />
            ))}
          </>
        </TableRowCollapsed>
      )}
    </>
  );
};
