import { SyntheticEvent, useState } from 'react';

import { Autocomplete, Box, Popover } from '@mui/material';

import { TextField } from 'ui/textField/TextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { TimesheetMember } from '../AddMemberRow.types';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';

import * as styles from './AddMemberPopover.styles';
import { AddMemberPopoverProps } from './AddMemberPopover.types';

export const AddMemberPopover = ({ availableMembers, anchorEl, onClose, onSave }: AddMemberPopoverProps) => {
  const { formatMessage } = useLocale();
  const [selectedMembers, setSelectedMembers] = useState<TimesheetMember[]>([]);
  const { billingCycleId } = useProjectTimesheetData();

  const placeholder =
    selectedMembers.length === 0
      ? formatMessage({ id: AppMessages['projectDetails.timesheet.addTeamModal.addMember'] })
      : '';

  const handleChange = (e: SyntheticEvent<Element, Event>, values: TimesheetMember[]) => setSelectedMembers(values);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSave({
        assignmentIds: selectedMembers.map((member) => member.id),
        billingCycleId,
      });
      setSelectedMembers([]);
      onClose();
    }
  };

  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Box sx={styles.wrapper(anchorEl?.clientWidth || 0)}>
        <Autocomplete
          value={selectedMembers}
          onChange={handleChange}
          options={availableMembers}
          getOptionLabel={(option) => option.fullName}
          sx={styles.autocomplete}
          multiple
          onKeyDown={handleKeyDown}
          autoFocus
          clearOnEscape
          onAbort={() => setSelectedMembers([])}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
          componentsProps={{ paper: { sx: styles.paper } }}
        />
      </Box>
    </Popover>
  );
};
