import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';
import { useMutation } from '../useMutation/useMutation';
import { addMembersToTeam } from 'api/actions/addMembersToTeam/addMembersToTeam';

export const useAddMembersToTeam = (workStatementId: string, teamId: string) => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['addMembersToTeam'], addMembersToTeam(workStatementId, teamId), {
    onSuccess: () => {
      invalidateBillingCycleCache();
    },
  });
};
