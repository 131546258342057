import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { DetailsGridItem } from '../detailsGrid/detailsGridItem/DetailsGridItem';
import { DetailsGrid } from '../detailsGrid/DetailsGrid';
import { Translation } from 'ui/translation/Translation';
import { useUpdateWorkStatementNote } from '../../../../../hooks/useUpdateWorkStatementNote/useUpdateWorkStatementNote';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { TextAreaEdit } from '../../../../../ui/form/inlineEdit/textAreaEdit/TextAreaEdit';

import { GeneralInformationProps } from './GeneralInformation.types';
import { MembersSummary } from './membersSummary/MembersSummary';
import * as styles from './GeneralInformation.styles';

export const GeneralInformation = ({ workStatement }: GeneralInformationProps) => {
  const { mutateAsync: updateWorkOrderNote, isLoading } = useUpdateWorkStatementNote(workStatement.id);
  const { showWorkStatementSnapshot } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();

  const commonProps = {
    onSubmit: updateWorkOrderNote,
    loading: isLoading,
    disabled: showWorkStatementSnapshot,
  };

  const members = workStatement?.teams?.flatMap((team) => team.members);

  return (
    <Box sx={styles.container}>
      <Typography variant="subtitle2" mb={2}>
        <Translation id="projectDetails.billingSummary.workStatementDetails.generalInformation" />
      </Typography>

      {members && workStatement.teams && <MembersSummary members={members} teamsCount={workStatement.teams?.length} />}

      <DetailsGrid>
        <DetailsGridItem
          labelTranslationId="projectDetails.billingSummary.workStatementDetails.generalInformation.orderStartDate"
          sx={styles.text}
        >
          {workStatement.startDate}
        </DetailsGridItem>
        <DetailsGridItem
          labelTranslationId="projectDetails.billingSummary.workStatementDetails.generalInformation.orderEndDate"
          sx={styles.text}
        >
          {workStatement.endDate || '-'}
        </DetailsGridItem>
        <DetailsGridItem
          labelTranslationId="projectDetails.billingSummary.workStatementDetails.generalInformation.paymentPeriod"
          sx={styles.text}
        >
          {workStatement.paymentPeriod || '-'}
        </DetailsGridItem>
        <DetailsGridItem
          labelTranslationId="projectDetails.billingSummary.workStatementDetails.generalInformation.billingCyclePeriod"
          sx={styles.text}
        >
          <Translation id="projectDetails.billingSummary.workStatementDetails.generalInformation.billingCyclePeriod.month" />
        </DetailsGridItem>
        {!isReport && (
          <DetailsGridItem span={2} sx={styles.wrapper}>
            <TextAreaEdit
              name="note"
              value={workStatement.note || ''}
              label="projectDetails.billingSummary.workStatementDetails.addComment"
              defaultValues={{ note: workStatement.note }}
              {...commonProps}
            />
          </DetailsGridItem>
        )}
      </DetailsGrid>
    </Box>
  );
};
