import { Stack } from '@mui/material';
import { Typography } from 'lux/components';

import { Translation } from 'ui/translation/Translation';
import * as styles from '../GeneralInformation.styles';

import { MembersSummaryProps } from './MembersSummary.types';
import { RandomAvatars } from './randomAvatars/RandomAvatars';

export const MembersSummary = ({ members, teamsCount }: MembersSummaryProps) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={2} sx={styles.summaryContainer}>
    <RandomAvatars members={members} />
    <Typography variant="body2" color="text.secondary" ml={1} fontWeight="medium" sx={styles.text}>
      {teamsCount} <Translation id="projectDetails.billingSummary.workStatementDetails.generalInformation.teams" />,{' '}
      {members.length}{' '}
      <Translation id="projectDetails.billingSummary.workStatementDetails.generalInformation.members" />
    </Typography>
  </Stack>
);
