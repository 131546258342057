import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../../../workStatementTableCell/WorkStatementTableCell';
import { WorkStatementAvatarCellSkeleton } from '../../../workStatementAvatarCell/WorkStatementAvatarCell.skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './WorkStatementTeamMember.styles';
import { WorkStatementTeamMemberSkeletonProps } from './WorkStatementTeamMember.types';

export const WorkStatementTeamMemberSkeleton = ({ firstColumnSize }: WorkStatementTeamMemberSkeletonProps) => (
  <Table.Row>
    <Table.Cell sx={styles.firstColumn(firstColumnSize)}>
      <WorkStatementAvatarCellSkeleton />
    </Table.Cell>
    <TableCell autoWidth>
      <TextSkeleton width={30} variant="subtitle2" />
    </TableCell>
    <TableCell autoWidth sx={styles.hoursCell}>
      <TextSkeleton width={30} variant="subtitle2" />
    </TableCell>
    <TableCell>
      <TextSkeleton width={80} variant="subtitle2" />
    </TableCell>
    <TableCell>
      <TextSkeleton width={80} variant="subtitle2" />
    </TableCell>
  </Table.Row>
);
