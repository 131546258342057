import { Styles } from 'lux/theme';

export const teamInfo: Styles = {
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
};

export const teamEdit: Styles = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
};

export const editButton: Styles = {
  width: 40,
  color: 'text.secondary',

  ['@media print']: {
    display: 'none',
  },
};

export const teamNameTextField: Styles = {
  flex: 1,
  height: 30,
  backgroundColor: 'primary.contrast',
  marginLeft: -1,
  marginRight: 1,
  '&.Mui-focused': {
    backgroundColor: 'primary.contrast',
    outline: '2px solid',
    outlineColor: (theme) => theme.palette.primary.main,
  },
  '&:not(.Mui-focused):hover': {
    backgroundColor: 'primary.contrast',
    opacity: 0.6,
  },
  '&& input': {
    paddingLeft: '8px',
  },
  '&&': {
    borderRadius: '4px',
  },
};

export const teamName: Styles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
