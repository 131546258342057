import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { UpdateTeamNameParams, UpdateTeamNameResponse } from './updateTeamName.types';

export const updateTeamName: (
  workStatementId: string,
  teamId: string,
) => MutationFn<UpdateTeamNameParams, UpdateTeamNameResponse> = (workStatementId, teamId) => (body) => ({
  endpoint: `/work-statements/${workStatementId}/teams/${teamId}/name`,
  method: 'PATCH',
  params: body,
});
