import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { CloseTeamParams } from './closeTeam.types';

export const closeTeam: (workStatementId: string, teamId: string) => MutationFn<CloseTeamParams> =
  (workStatementId, teamId) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/teams/${teamId}/close`,
    method: 'PATCH',
    params: body,
  });
