import { Button, Typography } from 'lux/components';
import { AddIcon } from 'lux/icons';

import { useAddTeamToWorkStatement } from 'hooks/useAddTeamToWorkStatement/useAddTeamToWorkStatement';
import { TableCell, TableRow } from '../../tableComponents/tableComponents';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { TeamRow } from '../teamRow/TeamRow';
import { Translation } from 'ui/translation/Translation';
import { sortArr } from 'utils/sortArr';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';

import * as styles from './WorkStatementRow.styles';
import { WorkStatementRowProps } from './WorkStatementRow.types';

export const WorkStatementRow = ({ workStatement }: WorkStatementRowProps) => {
  const { mutate: addTeamToWorkStatement } = useAddTeamToWorkStatement(workStatement.id);
  const { timesheetMonthDatesParsed: days } = useProjectTimesheet();
  const { billingCycleId, showTimesheetSnapshot, isReport } = useProjectTimesheetData();

  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();

  const handleAddTeam = () => {
    addTeamToWorkStatement({ billingCycleId });
  };

  const alphabeticallySortedTeams = sortArr([...workStatement.teams], (a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <TableRow>
        <TableCell sx={styles.workStatementCell(false)}>
          <Typography variant="buttonSmall" sx={styles.workStatementName}>
            {workStatement.statementNumber}
          </Typography>
          {process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && !isReport && (
            <Button
              endIcon={<AddIcon />}
              size="small"
              variant="text"
              disabled={showTimesheetSnapshot}
              sx={styles.workStatementEdit}
              onClick={handleAddTeam}
            >
              <Translation id="projectDetails.timesheet.addTeam" />
            </Button>
          )}
        </TableCell>
        {days.map((day, idx) => {
          const { isProjectStartDayMarker } = getIsFirstDayOfProject(Number(day.number));

          return <TableCell key={idx} sx={styles.workStatementCell(isProjectStartDayMarker)} />;
        })}
      </TableRow>
      {alphabeticallySortedTeams.map((team, idx) => (
        <TeamRow
          key={team.id}
          team={team}
          workStatementId={workStatement.id}
          teamIdx={idx}
          teamsAmount={workStatement.teams.length}
        />
      ))}
    </>
  );
};
