import { WorkStatementTeam, WorkStatementTeamMember } from 'api/types/BillingSummary.types';

const SPACE_IN_CHARS = 1;
export const getTheLongestFullNameFromTeams = (teams: WorkStatementTeam[]) =>
  teams.reduce((maxInTeams, team) => Math.max(maxInTeams, getTheLongestFullNameFromMembers(team.members)), 0);

export const getTheLongestFullNameFromMembers = (members: WorkStatementTeamMember[]) =>
  members.reduce(
    (maxInMembers, name) => Math.max(maxInMembers, name.firstName.length + SPACE_IN_CHARS + name.lastName.length),
    0,
  );
