import { Dialog } from '@mui/material';

import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { DiscrepancyHoursWarning } from '../../discrepancyHoursWarning/DiscrepancyHoursWarning';
import { ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useConfirmInvoice } from 'hooks/useConfirmInvoice/useConfirmInvoice';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { WarningMessage } from '../../discrepancyHoursWarning/warningMessage/WarningMessage';

import * as styles from './ApproveBillingCycleWarningModal.styles';
import { ApproveBillingCycleWarningModalProps } from './ApproveBillingCycleWarningModal.types';

export const ApproveBillingCycleWarningModal = ({
  open,
  onClose,
  title = '',
  isFormValid = true,
  invoiceId,
  people,
}: ApproveBillingCycleWarningModalProps) => {
  const { t } = useLocale();
  const { forceConfirm } = useConfirmInvoice(invoiceId);
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogInnerWrapper
        onClose={onClose}
        isFormValid={isFormValid}
        onSubmit={forceConfirm}
        title={title}
        submitText={t('projectDetails.billingSummary.error.approve')}
      >
        <DiscrepancyHoursWarning
          message={<WarningMessage people={people} />}
          actionLabel={'projectDetails.billingSummary.warning.actionLabel'}
          to={`../${ProjectDetailsRoute.timesheet}`}
          modifiedText={true}
        />
      </DialogInnerWrapper>
    </Dialog>
  );
};
