import { SxProps } from '@mui/system';

export const firstColumn = (firstColumnSize: number): SxProps => ({
  '&.MuiTableCell-body.MuiTableCell-root': {
    width: `${firstColumnSize}ch`,
  },
});

export const hoursCell: SxProps = {
  '&.MuiTableCell-body.MuiTableCell-root': {
    width: '90px', // INFO: we can't set less because in table head there is a TableSortLabel with fixed width
  },
};
