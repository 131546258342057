import { Styles } from 'styles/theme';

export const wrapper = (parentWidth = 0): Styles => ({
  width: parentWidth + 40,
  border: '2px solid',
  borderColor: 'primary.main',
});

export const paper: Styles = {
  fontSize: 14,
};

export const autocomplete: Styles = {
  flex: 1,
  height: '100%',
  '&& > div': {
    height: '100%',
  },
  borderRadius: '0',
  '&& > div > div': {
    fontSize: 14,
    minHeight: 0,
    borderRadius: 0,
    padding: '0 10px',
  },
  '& fieldset': {
    border: 'none',
  },
};
