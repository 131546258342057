import { Table } from 'lux/components';
import { Stack } from '@mui/material';

import { CollapseRow } from '../../collapseRow/CollapseRow';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import { WorkStatementTeamMemberSkeleton } from './workStatementTeamMember/WorkStatementTeamMember.skeleton';
import * as styles from './WorkStatementTeam.styles';
import { WorkStatementTeamSkeletonProps } from './WorkStatementTeam.types';

export const WorkStatementTeamSkeleton = ({ firstColumnSize }: WorkStatementTeamSkeletonProps) => (
  <>
    <Table.Row sx={styles.teamRow}>
      <Table.Cell>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <TextSkeleton width={80} variant="subtitle2" />
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <TextSkeleton width={30} variant="subtitle2" />
      </Table.Cell>
      <Table.Cell>
        <TextSkeleton width={30} variant="subtitle2" />
      </Table.Cell>
      <Table.Cell>
        <TextSkeleton width={24} variant="subtitle2" />
      </Table.Cell>
      <Table.Cell>
        <TextSkeleton width={80} variant="subtitle2" />
      </Table.Cell>
    </Table.Row>
    <CollapseRow isExpanded colSpan={5}>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <WorkStatementTeamMemberSkeleton key={index} firstColumnSize={firstColumnSize} />
        ))}
    </CollapseRow>
  </>
);
