import { useState, useMemo, useLayoutEffect, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import {
  addMonthToDate,
  formatDate,
  getCurrentDayStartDate,
  parseISODate,
  subtractMonthFromDate,
} from 'utils/dateUtils';
import { useBillingSummary } from 'hooks/useBillingSummary/useBillingSummary';
import { ProjectBillingSummaryDataContext } from '../projectBillingSummaryDataContext/ProjectBillingSummaryDataContext';
import { useReport } from 'hooks/useReport/useReport';
import { useProjectBillingSummary } from 'hooks/useProjectBillingSummary/useProjectBillingSummary';
import { projectsKeys } from 'utils/queryKeys';
import {
  ConfirmInvoiceError,
  ProjectBillingSummaryDataContextType,
} from '../projectBillingSummaryDataContext/ProjectBillingSummaryDataContext.types';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { useSessionStorage } from 'hooks/useSessionStorage/useSessionStorage';
import { useLeaveRoute, userNavigationState } from 'hooks/useLeaveRoute/useLeaveRoute';
import { SelectedBillingCycleKey } from 'api/types/Timesheet.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';

import { isStartOrEndDateAfterJanuary } from './ProjectBillingSummaryDataContextController.utils';
import { ProjectBillingSummaryDataContextControllerProps } from './ProjectBillingSummaryDataContextController.types';

export const confirmInvoiceErrorDefaultState: ConfirmInvoiceError = {
  message: null,
  invoiceNumber: null,
  errorType: null,
  invoiceId: null,
};

export const ProjectBillingSummaryDataContextController = ({
  children,
}: ProjectBillingSummaryDataContextControllerProps) => {
  const {
    projectDetails: { stage, id: projectId, startDate, endDate },
  } = useProjectDetails();
  // Right now we have to pass current Date as initial state because if we provide earlier than project start date backend will not provide us data (applies to the case when we create project not 1 day of month)
  const [selectedBillingCycle, setSelectedBillingCycle] = useSessionStorage(
    SelectedBillingCycleKey.SelectedBillingCycle,
    userNavigationState(projectId, new Date().toISOString()),
  );
  const [isNavigationReset] = useLeaveRoute(projectId, selectedBillingCycle.projectId);

  const [selectedDate, setSelectedDate] = useState<Date>(parseISODate(selectedBillingCycle.date));

  useLayoutEffect(() => {
    if (isNavigationReset) {
      setSelectedDate(new Date());
    }
  }, [isNavigationReset]);

  const [confirmInvoiceError, setConfirmInvoiceError] = useState<ConfirmInvoiceError>(confirmInvoiceErrorDefaultState);

  const enableBillingCycleFetch =
    ![ProjectStage.lead, ProjectStage.archived].includes(stage) && isStartOrEndDateAfterJanuary(startDate, endDate);

  const { groupBy } = useProjectBillingSummary();
  const {
    data: billingSummaryData,
    isLoading,
    fetchStatus,
    error,
  } = useBillingSummary(projectId, formatDate(selectedDate), groupBy, enableBillingCycleFetch);
  const { isReport, reportDate } = useReport();

  // isLoadingAndEnabled from new RQ abstraction from latest starter
  const isLoadingBillingSummaryData = isLoading && fetchStatus !== 'idle';

  const billingCycleStatus = billingSummaryData?.billingCycle?.status;
  const invoiceStatus = billingSummaryData?.currentData?.workStatements[0]?.invoice?.status;
  const invoiceNote = billingSummaryData?.currentData?.workStatements[0]?.invoice?.note;
  const queryClient = useQueryClient();
  const invalidateBillingSummaryCache = () =>
    queryClient.invalidateQueries(projectsKeys.projectBillingSummary(projectId, formatDate(selectedDate), groupBy));
  const billingCycle =
    billingSummaryData?.billingCycle && billingCycleStatus ? billingSummaryData.billingCycle : undefined;

  const lastManualUpdate = billingSummaryData?.lastManualUpdate;

  const snapshotCreateDate = billingSummaryData?.snapshotData?.createdAt;
  const currentDataWorkStatements = billingSummaryData?.currentData?.workStatements;
  const snapshotDataWorkStatements = billingSummaryData?.snapshotData?.workStatements;
  const showWorkStatementSnapshot = Boolean(billingSummaryData?.snapshotData);
  const workStatements = showWorkStatementSnapshot ? snapshotDataWorkStatements : currentDataWorkStatements;

  const billingSummaryPeople = workStatements && workStatements.flatMap((ws) => ws.members);

  useEffect(() => {
    if (confirmInvoiceError.invoiceNumber) {
      sessionStorage.setItem('confirmInvoiceError', JSON.stringify(confirmInvoiceError));
    }
  }, [confirmInvoiceError]);

  useLayoutEffect(() => {
    if (isReport && reportDate) {
      setSelectedDate(new Date(reportDate));
    }
  }, [isReport, reportDate]);

  const selectDateAndResetErrors = (date: Date) => {
    setSelectedDate(date);
    setConfirmInvoiceError(confirmInvoiceErrorDefaultState);
  };

  const saveBillingSummaryMonthStartDate = (monthStartDate: Date) => {
    selectDateAndResetErrors(monthStartDate);
    setSelectedBillingCycle(userNavigationState(projectId, monthStartDate.toISOString()));
  };

  const goToPreviousMonth = () => {
    const previousMonthStartDate = subtractMonthFromDate(selectedDate);
    saveBillingSummaryMonthStartDate(previousMonthStartDate);
  };

  const goToNextMonth = () => {
    const nextMonthStartDate = addMonthToDate(selectedDate);
    saveBillingSummaryMonthStartDate(nextMonthStartDate);
  };

  const goToCurrentMonth = () => {
    saveBillingSummaryMonthStartDate(getCurrentDayStartDate());
  };

  const isInvoiceEditBlocked = Boolean(
    invoiceStatus &&
      [
        InvoiceStatusEnum.approved_by_pm,
        InvoiceStatusEnum.paid,
        InvoiceStatusEnum.sent,
        InvoiceStatusEnum.invoice_issued,
      ].includes(invoiceStatus),
  );

  const value: ProjectBillingSummaryDataContextType = useMemo(
    () => ({
      isLoadingBillingSummaryData,
      billingCycle,
      workStatements: workStatements ?? [],
      dataMismatch: snapshotDataWorkStatements?.[0]?.dataMismatch,
      snapshotCreateDate,
      showWorkStatementSnapshot,
      projectId,
      lastManualUpdate,
      invalidateBillingSummaryCache,
      selectedDate,
      isReport,
      goToPreviousMonth,
      goToNextMonth,
      goToCurrentMonth,
      billingSummaryPeople,
      billingSummaryError: error,
      confirmInvoiceError,
      setConfirmInvoiceError,
      isInvoiceEditBlocked,
      invoiceNote,
    }),
    [
      isLoadingBillingSummaryData,
      billingCycle,
      workStatements,
      snapshotDataWorkStatements?.[0]?.dataMismatch,
      snapshotCreateDate,
      showWorkStatementSnapshot,
      projectId,
      lastManualUpdate,
      invalidateBillingSummaryCache,
      selectedDate,
      isReport,
      goToPreviousMonth,
      goToNextMonth,
      goToCurrentMonth,
      billingSummaryPeople,
      error,
      confirmInvoiceError,
      setConfirmInvoiceError,
      isInvoiceEditBlocked,
      invoiceNote,
    ],
  );

  return (
    <ProjectBillingSummaryDataContext.Provider value={value}>{children}</ProjectBillingSummaryDataContext.Provider>
  );
};
