import { SxProps } from '@mui/system';

import { markerBorderLineWidth, startDayCellWidth } from 'utils/timesheetProjectStartDayIndicator';

export const cell = (isProjectStartDayMarker: boolean): SxProps => ({
  ...(isProjectStartDayMarker && {
    borderLeft: `${markerBorderLineWidth}px solid orange`,
    width: startDayCellWidth,
    position: 'relative',

    // we need to mask a piece of bottom border in a cell with orange color
    '&:before': {
      position: 'absolute',
      left: -markerBorderLineWidth,
      top: -2,
      content: '""',
      display: 'block',
      height: 2,
      width: markerBorderLineWidth,
      backgroundColor: 'orange',
    },
  }),
});
