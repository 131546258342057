import { useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { TableCell, TableRowCollapsed, TableRow } from '../../tableComponents/tableComponents';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { HoursSum } from '../hoursSum/HoursSum';
import { TeamMemberRow } from '../teamMemberRow/TeamMemberRow';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useUpdateTeamName } from 'hooks/useUpdateTeamName/useUpdateTeamName';
import { EmptyHourDayCells } from '../emptyHourDayCells/EmptyHourDayCells';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useRemoveMemberFromTeam } from 'hooks/useRemoveMembersFromTeam/useRemoveMembersFromTeam';
import { roundNumber } from 'utils/roundNumber';

import * as styles from './TeamRow.styles';
import { TeamRowProps } from './TeamRow.types';
import { TeamName } from './teamName/TeamName';
import { AddMemberRow } from './addMemberRow/AddMemberRow';
import { TimesheetMember } from './addMemberRow/AddMemberRow.types';
import { DeleteTeamRow } from './deleteTeamRow/DeleteTeamRow';
import { AssignMemberWithoutNeed } from './assignMemberWithoutNeed/AssignMemberWithoutNeed';

const MIN_AMOUNT_OF_TEAMS_IN_WORK_STATEMENT = 1;

export const TeamRow = ({ team, teamIdx, workStatementId, teamsAmount }: TeamRowProps) => {
  const { projectId, billingCycleId, isReport } = useProjectTimesheetData();
  const {
    projectDetails: { assignments },
  } = useProjectDetails();
  const { mutate: removeMemberFromTeam } = useRemoveMemberFromTeam(workStatementId, team.id);
  const { mutate: updateTeamName } = useUpdateTeamName(projectId, workStatementId, team.id);
  const [isTeamRowExpanded, setIsTeamRowExpanded] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const teamHoursSum = roundNumber(team.teamHoursSum, 2);

  const handleSaveTeamName = (newTeamName: string) => {
    updateTeamName({ name: newTeamName, billingCycleId });
    setIsEditMode(false);
  };

  const onMemberRemove = (teamMemberId: string) =>
    removeMemberFromTeam({ billingCycleId, teamMemberIds: [teamMemberId] });

  // TODO fix recommendations TLI-504
  const availableMembers: TimesheetMember[] = useMemo<TimesheetMember[]>(
    () =>
      assignments
        .filter((assignment) => team.members.every((member) => member.assignmentId !== assignment.id))
        .sort((a, b) => a.person.lastName.localeCompare(b.person.lastName))
        .map(({ id, person }) => ({
          id,
          fullName: `${person.firstName} ${person.lastName}`,
        })),
    [assignments, team.members],
  );

  const handleSetIsEditMode = (newState: boolean) => {
    setIsTeamRowExpanded(true);
    setIsEditMode(newState);
  };

  const isTeamRemovable = team.members.length === 0 && teamsAmount > MIN_AMOUNT_OF_TEAMS_IN_WORK_STATEMENT;

  return (
    <>
      <TableRow sx={teamIdx > 0 ? styles.teamWrapper : {}}>
        <TableCell sx={styles.teamCell}>
          <Box sx={styles.innerWrapper}>
            <TeamName
              teamName={team.name}
              isEditMode={isEditMode}
              setIsEditMode={handleSetIsEditMode}
              onHandleSaveTeamName={handleSaveTeamName}
            />
            {!isEditMode && !isReport && (
              <CollapseButton isExpanded={isTeamRowExpanded} onClick={() => setIsTeamRowExpanded(!isTeamRowExpanded)} />
            )}
            <HoursSum>{teamHoursSum}</HoursSum>
          </Box>
        </TableCell>
        <EmptyHourDayCells />
      </TableRow>

      <TableRowCollapsed isExpanded={isTeamRowExpanded}>
        <>
          {team.members.length > 0 &&
            team.members.map((member) => (
              <TeamMemberRow
                isParentExpanded={isTeamRowExpanded}
                key={member.id}
                member={member}
                isEditMode={isEditMode}
                onMemberRemove={onMemberRemove}
              />
            ))}
        </>
      </TableRowCollapsed>

      <TableRowCollapsed isExpanded={isEditMode}>
        <>
          {process.env?.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && (
            <AddMemberRow availableMembers={availableMembers} workStatementId={workStatementId} teamId={team.id} />
          )}
          <AssignMemberWithoutNeed />
        </>
        {process.env?.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && isTeamRemovable && (
          <DeleteTeamRow workStatementId={workStatementId} teamId={team.id} />
        )}
      </TableRowCollapsed>
    </>
  );
};
