import { MouseEventHandler, useState } from 'react';

import { Box } from '@mui/material';
import { Button } from 'lux/components';
import { AddUserIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { useAddMembersToTeam } from 'hooks/useAddMembersToTeam/useAddMembersToTeam';
import { EmptyHourDayCells } from 'app/project/projectTimesheet/timesheetTable/timesheetTableBody/emptyHourDayCells/EmptyHourDayCells';
import { TableCell, TableRow } from 'app/project/projectTimesheet/timesheetTable/tableComponents/tableComponents';
import { HoursSum } from 'app/project/projectTimesheet/timesheetTable/timesheetTableBody/hoursSum/HoursSum';

import * as styles from './AddMemberRow.styles';
import { AddMemberProps } from './AddMemberRow.types';
import { AddMemberPopover } from './addMemberPopover/AddMemberPopover';

export const AddMemberRow = ({ availableMembers, workStatementId, teamId }: AddMemberProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { mutate: addMembersToTeam } = useAddMembersToTeam(workStatementId, teamId);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => setAnchorEl(event.currentTarget);
  const handleClosePopover = () => setAnchorEl(null);

  return (
    <TableRow>
      <TableCell sx={styles.cell}>
        <Box sx={styles.innerWrapper}>
          <Button
            onClick={handleClick}
            size="small"
            variant="text"
            fullWidth
            startIcon={<AddUserIcon />}
            sx={styles.addMemberButton}
            disabled={!!anchorEl}
          >
            <Translation id="projectDetails.timesheet.addTeamMember" />
          </Button>
          <HoursSum />
        </Box>
        <AddMemberPopover
          availableMembers={availableMembers}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          onSave={addMembersToTeam}
        />
      </TableCell>
      <EmptyHourDayCells />
    </TableRow>
  );
};
