import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './BillingSummaryWorkStatement.styles';
import { WorkStatementByTeamSkeleton } from './workStatementByTeam/WorkStatementByTeam.skeleton';
import { WorkStatementSummarySkeleton } from './workStatementSummary/WorkStatementSummary.skeleton';

export const BillingSummaryWorkStatementSkeleton = () => (
  <>
    <Skeleton width="100%" height={64} sx={styles.skeleton} />
    <WorkStatementByTeamSkeleton />
    <WorkStatementSummarySkeleton />
  </>
);
